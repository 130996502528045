/* Estilos del contenedor del chatbot */
.chatbot-container {
  max-width: 100%;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 95vh;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  justify-content: center;
}

/* Estilos de la caja de chat */
.chat-box {
  border: 1px solid #ddd;
  border-top: 1px solid #ddd;
  padding: 12px;
  flex-grow: 1;
  margin-top: 40px;
  margin-bottom: 10px;
  font-family: 'Franklin Gothic', Arial, sans-serif;
  word-wrap: break-word;
  font-size: 16px;
  overflow-y: auto;
  max-height: calc(100% - 80px);
  scroll-behavior: auto;
}

/* Estilos de la entrada de texto */
.chat-input {
  width: calc(100% - 20px);
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-top: 10px;
  font-size: 16px;
  font-family: 'Franklin Gothic', Arial, sans-serif;
}

/* Estilos de los botones de pregunta */
.question-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Dos columnas */
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.button-quiz {
  padding: 10px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #304f7c;
  color: white;
  width: 95%; /* Ocupa el 95% del espacio del grid */
  margin: 0 auto;
  font-family: 'Franklin Gothic', Arial, sans-serif;
  font-size: 14px;
}

/* Ajustes específicos para pantallas más pequeñas */
@media (max-width: 768px) {
  .question-buttons {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 600px) and (orientation: landscape) {
  .question-buttons {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .chatbot-container {
    height: calc(95vh - 70px);
  }

  .chat-box {
    height: 200px;
    scroll-behavior: auto;
  }

  .chat-input {
    width: calc(100% - 10px);
  }
}