.quiz-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  text-align: center;
  position: relative;
}

/* Estilos para la pantalla de inicio */
.start-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin: 0;
}

.start-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  font-family: 'Filicudi Solid', Arial, sans-serif;
}

.start-subtitle {
  font-size: 1.5rem;
  margin-bottom: 15px;
  margin-left: -35px;
  font-family: 'Franklin Gothic', Arial, sans-serif;
}

.start-button {
  background-color: #304f7c;
  color: white;
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'Franklin Gothic', Arial, sans-serif;
}

.start-button:hover {
  background-color: #597296;
}

/* Estilos para la pregunta */
.question-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
}

.question-title {
  font-size: 2rem;
  margin-bottom: 10px;
  font-family: 'Filicudi Solid', Arial, sans-serif;
}

.question-text {
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-family: 'Franklin Gothic', Arial, sans-serif;
}

/* Estilos para las opciones */
.options-container {
  display: flex;
  flex-wrap: wrap; /* Permite que las opciones se distribuyan en varias filas si es necesario */
  justify-content: center; /* Centra las opciones en el contenedor */
  gap: 10px;
}

.option-button {
  background-color: #304f7c;
  color: white;
  padding: 10px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'Franklin Gothic', Arial, sans-serif;
  width: calc(50% - 20px); /* Hace que las opciones ocupen la mitad del ancho, menos el espacio de la brecha */
  box-sizing: border-box;
}

.option-button:hover {
  background-color: #597296;
}

.correct {
  background-color: green !important;
}

.incorrect {
  background-color: red !important;
}

/* Estilos para la barra de progreso */
.progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  height: 10px;
  margin-top: 20px;
}

.progress {
  height: 100%;
  background-color: #007bff;
  transition: width 0.3s;
}

/* Estilos para la pantalla de resultados */
.results-container {
  width: 100%;
}

.results-title {
  font-size: 2rem;
  margin-bottom: 20px;
  font-family: 'Filicudi Solid', Arial, sans-serif;
}

.score {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-family: 'Franklin Gothic', Arial, sans-serif;
}

.message {
  font-size: 1.2rem;
  margin-bottom: 20px;
  font-family: 'Franklin Gothic', Arial, sans-serif;
}

.restart-button {
  background-color: #304f7c;
  color: white;
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'Franklin Gothic', Arial, sans-serif;
}

.restart-button:hover {
  background-color: #597296;
}

/* Estilos responsivos */
@media (max-width: 600px) {
  .quiz-container {
    padding: 10px;
  }

  .question-title {
    font-size: 1.5rem;
  }

  .question-text {
    font-size: 1.2rem;
  }

  .option-button {
    font-size: 0.9rem;
    padding: 8px;
  }

  .start-title,
  .results-title {
    font-size: 2rem;
  }

  .score,
  .message {
    font-size: 1rem;
  }
}

/* Estilos para landscape en pantallas pequeñas */
@media (max-width: 600px) and (orientation: landscape) {
  .options-container {
    display: flex;
    flex-wrap: wrap; /* Mantiene el wrap para distribuir en filas */
    justify-content: center; /* Centrar las opciones horizontalmente */
  }

  .option-button {
    width: calc(50% - 20px); /* Asegura que las opciones ocupen el 50% del ancho disponible */
  }
}
