@font-face {
  font-family: 'Filicudi Solid';
  src: url('../../public/fonts/fonnts.com-Filicudi_Solid.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Franklin Gothic';
  src: url('../../public/fonts/Franklin-Gothic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.homepage {
  height: 100vh;
  background-image: url('../../public/image001.png');
  background-size: auto;
  background-repeat: repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  text-align: center;
  padding: 20px;
}

.logo-home {
  display: block;
  margin: 0 auto 20px auto;
  max-width: 350px;
  height: auto;
  padding: 10px;
  box-sizing: border-box;
  background-color: white;
}

@media (max-width: 768px) {
  .logo-home {
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  .logo-home {
    max-width: 200px;
  }
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

.title {
  font-size: 3em;
  color: black;
  background-color: white;
  margin-top: 10px;
  font-family: 'Filicudi Solid', Arial, sans-serif;
  padding: 5px;
  text-align: center;
  display: inline-block;
}

.subtitle {
  font-size: 1.5em;
  color: black;
  background-color: white;
  margin-top: 5px;
  font-family: 'Franklin Gothic', Arial, sans-serif;
  padding: 5px;
  text-align: center;
  display: inline-block;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -20px;
  margin-bottom: auto;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

button {
  background-color: #304f7c;
  color: white;
  padding: 10px 20px;
  margin: 0 50px 0 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1.4em;
  text-align: center;
  font-family: 'Franklin Gothic', Arial, sans-serif;
  width: 200px;
}

button:hover {
  background-color: #597296;
}

.menu {
  z-index: 2;
  position: relative;
  margin-top: -20px;
}

.bottom-left-image {
  position: absolute;
  bottom: 0;
  left: 55%;
  transform: translateX(-50%);
  width: 80vh;
  max-width: 80%;
  height: auto;
}

/* Ajustes específicos para pantallas horizontales grandes */
@media (min-width: 1024px) {
  .bottom-left-image {
    width: 80vh;
    left: 80%;
  }
}

/* Ajustes para pantallas muy bajas en orientación horizontal */
@media (max-height: 480px) and (orientation: landscape) {
  .logo-home {
    max-width: 150px;
  }

  .title {
    font-size: 2em;
    margin-top: 5px;
    text-align: center; /* Mover texto hacia la izquierda */
    padding-left: 20px;
  }

  .subtitle {
    font-size: 1em;
    margin-top: 3px;
    text-align: center; /* Mover subtítulo hacia la izquierda */
    padding-left: 20px;
  }

  .content {
    align-items: flex-start; /* Mover los botones hacia la izquierda */
    padding-left: 30%;
  }

  .buttons {
    gap: 10px;
  }

  .bottom-left-image {
    width: 70vh;
    max-width: 60%;
    left: 80%; /* Mover la imagen más hacia la derecha */
  }
}
