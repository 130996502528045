html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; /* Evita las barras de desplazamiento */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
