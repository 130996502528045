.menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  background-color: #f8f9fa;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 100;
}

.logo_menu {
  height: 30px;
  background-color: #f8f9fa;
  margin-top: 5px;
}

.home-button {
  background-color: #304f7c;
  color: white;
  margin-top: 5px;
  padding: 8px 10px;
  font-size: 1rem;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  font-family: 'Franklin Gothic', Arial, sans-serif;
  width: 200px;
}

.home-button:hover {
  background-color: #597296;
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 480px) {
  .menu {
    flex-direction: column;
    align-items: flex-start;
  }

  .home-button {
    width: 100%;
    text-align: center;
    margin-top: 5px;
  }
}

/* Ajustes específicos para landscape en pantallas pequeñas */
@media (max-width: 480px) and (orientation: landscape) {
  .menu {
    flex-direction: row; /* Mantener los elementos en fila en lugar de columna */
    justify-content: space-between; /* Espaciar los elementos a ambos lados */
    padding: 5px 15px; /* Aumentar ligeramente el padding horizontal */
  }

  .logo_menu {
    height: 25px; /* Reducir aún más el tamaño del logo en landscape */
    margin-top: 0; /* Eliminar el margen superior */
  }

  .home-button {
    padding: 5px 8px; /* Reducir el padding para ahorrar espacio */
    margin-top: 0; /* Eliminar el margen superior */
    font-size: 0.9rem; /* Reducir el tamaño de la fuente */
  }
}
