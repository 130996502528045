/* Contenedor principal que incluye el canvas y los controles */
.model-viewer-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
  }
  
  /* Estilo del canvas para el modelo 3D */
  .canvas {
    flex: 1;
    z-index: 0; /* Asegura que el canvas esté detrás del menú */
  }
  
  /* Controles en la parte inferior */
  .controls {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1; /* Asegura que los controles estén sobre el canvas */
    font-family: 'Franklin Gothic', Arial, sans-serif; /* Aplica la fuente Franklin Gothic */
  }

  .loading-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    color: #304f7c;
  }
  